<template>
  <div class="card mt-5 shadow bg-light">
    <div class="text-center">
      <img src="../assets/images/bemag-full-grey-500.png" class="card-img-top img-fluid" style="max-width: 600px" alt="Bemag">
    </div>

    <div v-if="allow" class="card-body">

      <div class="text-center">
        <h3 class="card-title">Cambiar contraseña</h3>
      </div>

      <div class="form-group">
        <label for="passwd">Nueva contraseña:</label>
        <input type="password" class="form-control" v-model="passwd">
      </div>

      <div class="form-group">
        <label for="passwd2">Repetir contraseña:</label>
        <input type="password" class="form-control" v-model="passwd2">
      </div>

      <div class="text-center">
        <LoadingButton @clicked="nuevapasswd" :iconProp="'fas fa-paper-plane'" :loadingProp="loading" :classProp="'btn btn-success'" :textProp="'Enviar'" class="m-1"></LoadingButton>
        <router-link to="/login" class="btn m-1 btn-secondary"><i class="fas fa-arrow-left"></i> Volver</router-link>
      </div>

    </div>

    <div v-else class="card-body text-center">
      <img src="../assets/images/cargando.gif">
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import config from '../config'
  import LoadingButton from '../components/LoadingButton.vue'

  export default {
    data() {
      return {
        allow: false,
        loading: false,
        codigo: null,
        passwd: null,
        passwd2: null
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      nuevapasswd: async function () {
        try {
          this.loading = true

          if (this.passwd !== this.passwd2) {
            this.loading = false

            Swal.fire({
              title: 'Error',
              text: 'Las contraseñas no coinciden',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })

            return
          }

          const res = await axios.post(config.apiUrl + '/usuarios/nuevapasswd', {
            codigo: this.codigo,
            passwd: this.passwd
          })

          await Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$router.push('login')
        } catch (e) {
          this.loading = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      comprobarCodigo: async function () {
        try {
          const url = new URL(window.location.href)
          this.codigo = url.searchParams.get("codigo")

          await axios.post(config.apiUrl + '/usuarios/comprobarcodigoreset', {
            codigo: this.codigo
          })

          this.allow = true
        } catch (e) {
          await Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$router.push('login')
        }
      }
    },
    mounted() {
      this.$store.commit('setPublicLayout')
      this.comprobarCodigo()
    }
  }
</script>
